var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b10-base',[_c('ValidationProvider',{attrs:{"name":"iban","rules":_vm.formRules.iban},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('AA## #### #### #### #### ####'),expression:"'AA## #### #### #### #### ####'"}],attrs:{"clearable":"","flat":"","label":"IBAN","error-messages":errors},on:{"input":_vm.inputIBAN},model:{value:(_vm.form.iban),callback:function ($$v) {_vm.$set(_vm.form, "iban", $$v)},expression:"form.iban"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"banco","rules":_vm.formRules.banco},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b10-autocomplete',{attrs:{"items":_vm.bancos,"item-text":"nombre","label":"Entidad bancaria","clearable":"","error-messages":errors,"return-object":""},on:{"change":_vm.changeBanco},model:{value:(_vm.form.banco),callback:function ($$v) {_vm.$set(_vm.form, "banco", $$v)},expression:"form.banco"}})]}}])}),_c('v-checkbox',{attrs:{"label":"Nacional"},model:{value:(_vm.form.nacional),callback:function ($$v) {_vm.$set(_vm.form, "nacional", $$v)},expression:"form.nacional"}}),_c('ValidationProvider',{attrs:{"name":"bic","rules":_vm.formRules.bic},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"flat":"","label":"BIC","error-messages":errors},model:{value:(_vm.form.bic),callback:function ($$v) {_vm.$set(_vm.form, "bic", $$v)},expression:"form.bic"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"ffirmamandato","rules":_vm.formRules.ffirmamandato},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b10-date-picker',{attrs:{"title":"Fecha de firma de mandato","error-messages":errors},model:{value:(_vm.form.ffirmamandato),callback:function ($$v) {_vm.$set(_vm.form, "ffirmamandato", $$v)},expression:"form.ffirmamandato"}})]}}])}),_c('b10-textarea',{attrs:{"label":"Observaciones"},model:{value:(_vm.form.observacion),callback:function ($$v) {_vm.$set(_vm.form, "observacion", $$v)},expression:"form.observacion"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }