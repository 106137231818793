<template>
  <b10-base>
    <ValidationProvider
      v-slot="{ errors }"
      name="iban"
      :rules="formRules.iban"
    >
      <v-text-field
        v-model="form.iban"
        v-mask="'AA## #### #### #### #### ####'"
        clearable
        flat
        label="IBAN"
        :error-messages="errors"
        @input="inputIBAN"
      />
    </ValidationProvider>
    <ValidationProvider
      v-slot="{ errors }"
      name="banco"
      :rules="formRules.banco"
    >
      <b10-autocomplete
        v-model="form.banco"
        :items="bancos"
        item-text="nombre"
        label="Entidad bancaria"
        clearable
        :error-messages="errors"
        return-object
        @change="changeBanco"
      />
    </ValidationProvider>
    <v-checkbox
      v-model="form.nacional"
      label="Nacional"
    />
    <ValidationProvider
      v-slot="{ errors }"
      name="bic"
      :rules="formRules.bic"
    >
      <v-text-field
        v-model="form.bic"
        flat
        label="BIC"
        :error-messages="errors"
      />
    </ValidationProvider>
    <ValidationProvider
      v-slot="{ errors }"
      name="ffirmamandato"
      :rules="formRules.ffirmamandato"
    >
      <b10-date-picker
        v-model="form.ffirmamandato"
        title="Fecha de firma de mandato"
        :error-messages="errors"
      />
    </ValidationProvider>
    <b10-textarea
      v-model="form.observacion"
      label="Observaciones"
    />
  </b10-base>
</template>

<script>
import formMixin from '@/mixins/formMixin'
import Data from './ClienteCuentaFormData'
import { isValidIBAN } from '@/utils/validate'
import  { extend } from 'vee-validate'
import _ from '@/utils/lodash'
import { currentDate } from '@/utils/date'

export default {
  mixins: [formMixin],
  props: {},
  data () {
    return {
      form: {
        banco: null,
        codigo: null,
        iban: null,
        observacion: null,
        bic: null,
        nacional: true,
        ffirmamandato: currentDate(),
      },
      formRules: {
        banco: { required: true },
        iban: { required: true, validIBAN: true },
        ffirmamandato: { required: true },
        bic: { required: true },
      },
      bancos: [],
    }
  },
  computed: {
    unmaskedIBAN () {
      return this.form.iban?.replace(/ /g,'')
    },
    codigoEntidadIBAN () {
      if (this.unmaskedIBAN && this.unmaskedIBAN.length >= 8) {
        return this.unmaskedIBAN.substring(4, 8)
      } else {
        return undefined
      }
    },
  },
  async created () {
    const resp = await Data.selectLookups(this)
    this.bancos = resp.data.selectBancos.result.dataset
    extend('validIBAN', {
      message: 'El IBAN no es correcto',
      validate: async (value) => {
        return {
          valid: isValidIBAN(value)
        }
      }
    })
    await this.loadForm()
  },
  methods: {
    async loadFormData (id) {
      const formData = await Data.selectFormData(this, id)
      this.form = formData
    },
    changeBanco (value) {
      if (value) {
        this.$set(this.form, 'bic', value.bic)
      } else {
        this.$set(this.form, 'bic', null)
      }
    },
    inputIBAN (value) {
      let banco = null
      if (value) {
        this.$set(this.form, 'iban', value.toUpperCase())
        if (this.codigoEntidadIBAN) {
          banco = _.find(this.bancos, { codigo: this.codigoEntidadIBAN })
        }
      }
      this.$set(this.form, 'banco', banco)
      // forzar evento
      this.changeBanco(banco)
    },
  },
}
</script>
