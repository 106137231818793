export default {
  async insert (Vue, formData, idcliente, idclientePotencial) {
    const resp = await Vue.$api.call(
      'clienteCuenta.insert',
      {
        values: {
          idbanco: formData.banco.idbanco,
          idcliente,
          control: formData.control,
          ncuenta: formData.ncuenta,
          swift: formData.swift,
          observacion: formData.observacion,
          principal: formData.principal,
          oficina: formData.oficina,
          nacional: formData.nacional,
          dir_oficina: formData.dir_oficina,
          iban: formData.iban,
          bic: formData.bic,
          ffirmamandato: formData.ffirmamandato,
          idmandato: formData.idmandato,
          idcliente_potencial: idclientePotencial,
        },
      },
    )
    return resp.data.result.dataset[0]
  },
}
