import { APIFilter } from '@/utils/api'

export default {
  async selectLookups (Vue) {
    let apiCalls = []
    const apiFilterEstadoActivo = new APIFilter()
    apiFilterEstadoActivo.addGT('estado', 0)
    apiFilterEstadoActivo.addIsNull('bic', true)
    apiCalls.push({ name: 'selectBancos', method: 'banco.select', params: { filter: apiFilterEstadoActivo } })
    return await Vue.$api.batchCall(apiCalls)
  },
  async selectFormData (Vue, id) {
    const apiFilter = new APIFilter()
    apiFilter
      .addExact('idcliente_cuenta', id)
    const resp = await Vue.$api.call('clienteCuenta.select', { filter: apiFilter })
    return resp.data.result.dataset[0]
  },
}
